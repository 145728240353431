.catalog-manage .list-group-item.active a.tag {
  color: #fff;
}
.catalog-manage .list-group-item.has-parent-tag {
  display: none;
}
.catalog-manage .list-group-item.has-parent-tag.sub-tag-visible {
  display: block;
}
.catalog-manage table .sortable-drag {
  background-color: #fff;
}