/* -- Imports -- */
h1 {
  color: #082C49;
  margin-top: 0;
}

h1 .badge {
  font-size: 50%;
}

h2.st {
  font-size: 22px;
  margin-top: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

a {
  color: #009EE0;
}

hr {
  border-color: #082C49;
}

pre {
  border-color: #eee;
  border-left-width: 5px;
  background: inherit;
  font-size: 12px;
}

.btn-success {
  background-color: #082C49;
  border-color: #082C49;
  color: #fff;
}
.btn-success:hover, .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus {
  background-color: #0c416b;
  border-color: #0d4877;
  box-shadow: 0 0 0 0.2rem rgba(45, 76, 100, 0.5);
}
.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 76, 100, 0.5);
}
.btn-success:disabled, .btn-success:disabled:hover, .btn-success.disabled, .btn-success.disabled:hover {
  background-color: #0c416b;
  border-color: #0d4877;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-loading {
  position: relative;
  color: transparent !important; /* Cache le texte du bouton */
}
.btn-loading::after {
  display: block;
  content: " ";
  width: 46px;
  margin: -20px auto 0;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: 33.3333333333% 100%;
  animation: loadingDots 1s infinite linear;
}

@keyframes loadingDots {
  33% {
    background-size: 33.3333333333% 0%, 33.3333333333% 100%, 33.3333333333% 100%;
  }
  50% {
    background-size: 33.3333333333% 100%, 33.3333333333% 0%, 33.3333333333% 100%;
  }
  66% {
    background-size: 33.3333333333% 100%, 33.3333333333% 100%, 33.3333333333% 0%;
  }
}
/*.btn-primary {
	background-color:rgb(175, 203, 81);
	border-color:rgb(175, 203, 81);
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
	background-color:rgba(175, 203, 81, 0.8);
	border-color:rgba(175, 203, 81, 0.8);
}
.btn-info {
	background-color:#009EE0;
	border-color:#009EE0;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
	background-color:rgba(0, 158, 224,.8);
	border-color:rgba(0, 158, 224,.8);
}
.btn-danger {
	background-color:rgb(232, 82,69);
	border-color:rgb(232, 82,69);
}
*/
.btn-group-xs > .btn, .btn-xs {
  padding: 0.35rem 0.3rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.alert-accessory {
  padding-left: 60px;
}
.alert-accessory:before {
  font-size: 1.6rem;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  display: block;
  padding: 0 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -19px;
}
.alert-accessory p:last-child {
  margin-bottom: 0;
}
.alert-accessory.alert-accessory-tip:before {
  content: "\F05A";
}
.alert-accessory.alert-accessory-warning:before {
  content: "\F071";
}

.ddbtn li.sep {
  width: 100%;
  padding: 2px 5px;
  background-color: #ddd;
  text-align: center;
  margin: 5px 0 0;
}

.ddbtn li.sep:first-child {
  margin-top: 0;
}

.dropdown-menu {
  min-width: 200px;
}

.toprint {
  display: none;
}

.italic {
  font-style: italic;
}

.yes {
  color: #00aa55;
}

.no {
  color: #D43F3A;
}

.hide {
  display: none !important;
}

/* Color Picker */
.color-picker .dropdown-toggle {
  border-color: #333;
  min-width: 30px;
}

.color-picker .dropdown-menu {
  width: 400px;
}
.color-picker .dropdown-menu li {
  display: inline-block;
  vertical-align: middle;
  margin: 5px 2px;
  width: 40px;
  overflow: hidden;
}
.color-picker .dropdown-menu li a {
  display: block;
  height: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
}
.color-picker .dropdown-menu li a.selected {
  border: 2px solid #333;
}

h3 span.info {
  font-size: 13px;
  color: #aaa;
  margin-left: 20px;
  font-weight: normal;
}

.clear {
  clear: both;
}

h3 select {
  margin-bottom: 2px;
}

fieldset {
  margin-bottom: 40px;
}

/* ---- Loader ----- */
@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader {
  -moz-animation: spinner 1250ms infinite linear;
  -webkit-animation: spinner 1250ms infinite linear;
  animation: spinner 1250ms infinite linear;
  border: 6px solid #082C49;
  border-right-color: transparent !important;
  border-radius: 16px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: inline-block;
}

.loader-sm {
  -moz-animation: spinner 1250ms infinite linear;
  -webkit-animation: spinner 1250ms infinite linear;
  animation: spinner 1250ms infinite linear;
  border: 2px solid #082C49;
  border-right-color: transparent !important;
  border-radius: 8px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.loading-state {
  position: relative;
  z-index: 30;
}
.loading-state:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.7;
  z-index: 31;
}
.loading-state:after {
  position: absolute;
  content: " ";
  left: 50%;
  top: 50%;
  margin-top: -16px;
  margin-left: -16px;
  z-index: 32;
  -moz-animation: spinner 1250ms infinite linear;
  -webkit-animation: spinner 1250ms infinite linear;
  animation: spinner 1250ms infinite linear;
  border: 6px solid #082C49;
  border-right-color: transparent !important;
  border-radius: 16px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: inline-block;
}

/* -- Imports -- */
/* -- Layout -- */
body {
  padding-top: 56px;
  background-color: #f8f8f8;
  color: #082C49;
  font-size: 0.9rem;
}
@media (max-width: 767px) {
  body {
    padding-top: 0;
  }
}

#header {
  background-color: #082C49;
}
@media (min-width: 321px) {
  #header .dropdown-menu {
    max-height: 650px;
    max-height: 100vh;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }
}
#header .brands-selector.searching .item-default {
  display: none;
}

#content.full {
  padding: 15px;
}

.cmain {
  margin-left: 235px;
  padding: 20px 15px 0 0;
  min-height: calc(100vh - 56px);
}
@media (max-width: 767px) {
  .cmain {
    margin: 0;
    padding: 20px;
  }
}

#footer {
  background-color: #fff;
  border-top: 1px solid #ccc;
  clear: both;
  padding: 5px 15px;
  margin: 30px -15px 0 -15px;
  color: #888;
  font-size: 0.8em;
  text-align: center;
}
#footer a {
  color: #15c;
}
#footer .logo {
  margin-right: 20px;
}

/* --- Sidebar --- */
.sidebar {
  position: static;
  width: 100%;
  height: auto;
  overflow-y: auto;
  background-color: #fff;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    padding-top: 76px;
    width: 220px;
    height: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }
}
.sidebar .mainnav {
  padding: 10px 0;
  color: #082C49;
}
.sidebar .mainnav .nav-item {
  width: 100%;
}
.sidebar .mainnav a {
  border-right: 2px solid transparent;
  border-left: 10px solid transparent;
  padding-left: 5px;
  padding-right: 13px;
  color: #999;
}
.sidebar .mainnav a, .sidebar .mainnav a:hover, .sidebar .mainnav a:focus {
  background: none;
}
.sidebar .mainnav a .fa {
  margin-right: 8px;
}
.sidebar .mainnav > li > a {
  color: #082C49;
}
.sidebar .mainnav .active > a,
.sidebar .mainnav a:hover {
  color: #082C49;
  text-decoration: underline;
}
.sidebar .mainnav a.announcekit-widget {
  color: #009EE0;
}
.sidebar .mainnav .subnav {
  display: none;
}
.sidebar .mainnav .subnav a {
  padding-left: 30px;
}
.sidebar .mainnav .opened .subnav {
  display: block;
}
.sidebar .mainnav .opened > a,
.sidebar .mainnav > .active > a {
  font-weight: bold;
  border-right: 2px solid #082C49;
  color: #082C49;
  text-decoration: none;
}
.sidebar .mainnav > li > a:hover,
.sidebar .mainnav > .active > a {
  background-color: #eee;
}

/* --- Mobile --- */
@media (max-width: 767px) {
  .navbar.fixed-top {
    position: static;
  }
}
@media (max-width: 767px) {
  #content .sidebar {
    display: none;
  }
  #content.side .sidebar {
    display: block;
  }
}
/* Toolbar edition */
.ztoolbar {
  padding: 1rem;
  background-color: #fff;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
  margin-bottom: 1rem;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.4);
}
.ztoolbar h1, .ztoolbar h2 {
  display: inline-block;
  font-size: 2rem;
  margin: 0 0 0 15px;
  vertical-align: middle;
  white-space: initial;
}

@media (min-width: 769px) {
  .sticky-after-nav, .ztoolbar {
    top: 56px;
  }
}
/* --- RSelection form --- */
#rselection-form {
  width: 100%;
  max-width: 400px;
  padding: 16px;
  margin: auto;
  background-color: #fff;
}
#rselection-form h1 {
  font-size: 24px;
  text-align: center;
}

@media (min-width: 769px) {
  #rselection-form {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}
/* -- Nav RSelection form --- */
#restaurant-filter-nav .dropdown-menu {
  padding: 0;
}
#restaurant-filter-nav .search {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
}
#restaurant-filter-nav .restaurant-tree {
  padding-bottom: 0.5rem;
}
#restaurant-filter-nav .group-item {
  position: relative;
  color: #7d7d7d;
  border-bottom: 1px solid #d0d0d0;
}
#restaurant-filter-nav .group-item:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #888;
  right: 1rem;
  top: 14px;
  position: absolute;
}
#restaurant-filter-nav .group-item:active, #restaurant-filter-nav .group-item.active {
  background-color: #eee;
  color: #212529;
}
#restaurant-filter-nav .group-container {
  display: none;
  text-indent: 0.8rem;
}
#restaurant-filter-nav .group-container .group-container {
  text-indent: 1.6rem;
}
#restaurant-filter-nav .group-container .group-container .group-container {
  text-indent: 2.4rem;
}
#restaurant-filter-nav .restaurant-tree.searching .group-container, #restaurant-filter-nav .restaurant-tree.searching .restaurant-item, #restaurant-filter-nav .restaurant-tree.searching .group-item {
  display: none;
}
#restaurant-filter-nav .restaurant-tree.searching .group-container.search-match, #restaurant-filter-nav .restaurant-tree.searching .restaurant-item.search-match, #restaurant-filter-nav .restaurant-tree.searching .group-item.search-match {
  display: block;
}

/* --- Other --- */
fieldset, .bg-section {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

iframe {
  border: none;
}

ul input[type=checkbox],
input[type=checkbox].nomargin {
  margin: 3px 3px 3px 4px;
}

.imgField input {
  display: inline-block;
}

.upImg_container {
  vertical-align: middle;
  position: relative;
  padding-right: 20px;
}

.form-compact {
  max-width: 500px;
}

legend .badge {
  font-size: 56%;
  vertical-align: middle;
}

.upImg {
  vertical-align: middle;
  max-height: 200px;
  max-width: 200px;
}

.upImg_container .close {
  position: absolute;
  top: -5px;
  right: -5px;
}

.table td {
  vertical-align: middle;
}

.table td.valign-top {
  vertical-align: top;
}

table img {
  vertical-align: middle;
  max-height: 100px;
  max-width: 100px;
}

.table tbody td.img {
  width: 120px;
  text-align: center;
  padding: 0;
  vertical-align: middle;
}

.table thead tr th.chk,
.table tbody td.chk {
  text-align: center;
  width: 35px;
  vertical-align: middle;
}

table th.color {
  width: 50px;
}

table td.tags_col,
table th.tags_col {
  max-width: 200px;
}

.table-sorted th a {
  color: #333;
}

.table-sorted th.sort a {
  text-decoration: underline;
}

.table-sorted th a:after {
  content: "";
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  width: 8px;
}

.table-sorted th a:hover:after,
.table-sorted th.asc a:after,
.table-sorted th.desc a:after {
  width: 0;
  height: 0;
  border-bottom: 4px solid;
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-left: 4px solid rgba(0, 0, 0, 0);
}

.table-sorted th.desc a:after {
  border-top: 4px solid;
  border-bottom: none;
}

.table .table-disabled {
  color: #999;
}

table a.tag, table span.tag, .ztags a.tag, .ztags span.tag {
  border-radius: 3px;
  background-color: #eee;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
  margin: 0 5px;
  display: inline-block;
}

.noresult,
table .noresult td {
  text-align: center;
  font-style: italic;
}

table .nw {
  white-space: nowrap;
}

.secondary-link, translate-link {
  white-space: nowrap;
  font-size: 12px;
  font-style: italic;
}

table td.main, table th.main {
  min-width: 400px;
}

table td.small_main, table th.small_main {
  min-width: 250px;
}

table.tags_list .subtag {
  padding-left: 20px;
}

.table {
  background-color: #fff;
}
.table > tbody > tr.expired > td,
.table > tbody > tr.expired > th,
.table > tbody > tr > td.expired,
.table > tbody > tr > th.expired,
.table > tfoot > tr.expired > td,
.table > tfoot > tr.expired > th,
.table > tfoot > tr > td.expired,
.table > tfoot > tr > th.expired,
.table > thead > tr.expired > td,
.table > thead > tr.expired > th,
.table > thead > tr > td.expired,
.table > thead > tr > th.expired {
  color: #b1b1b1;
  background-color: #f5f5f5;
}

.table .tsection td, .table .tsection th {
  background-color: #ececec;
  text-align: center;
}

.licenses_list .ended td {
  color: #777;
}

#table_list_dishes {
  display: block;
  overflow: auto;
  overflow-y: scroll;
  max-height: 400px;
}

#table_list_dishes thead th,
#table_list_dishes tbody td {
  width: 25px;
}

#table_list_dishes thead th + th,
#table_list_dishes tbody td + td {
  width: 280px;
}

#table_list_dishes thead th + th + th,
#table_list_dishes tbody td + td + td {
  width: 190px;
}

#table_list_dishes thead th + th + th + th,
#table_list_dishes tbody td + td + td + td {
  width: 50px;
}

table td.actions {
  text-align: center;
}

table td.actions form {
  margin: 5px 0;
  padding: 0;
}

.opening_hours th, .opening_hours td {
  text-align: center;
}

.opening_hours td .rm {
  visibility: hidden;
}

.opening_hours td:hover .rm {
  visibility: visible;
}

.op-error th {
  color: #A94442;
}

table td.price, table th.price {
  text-align: right;
}

.rights_list {
  padding-left: 0;
}

.rights_list, .rights_list ul {
  list-style: none;
}

label.right {
  padding-left: 15px;
  vertical-align: middle;
  line-height: 30px;
  cursor: pointer;
}
label.right input {
  vertical-align: middle;
  margin: 0 5px 2px;
}
label.right.lvl1 {
  font-size: 18px;
  margin-top: 50px;
}

.rights_list ul.disable {
  display: none;
}

/*.rights_list ul.disable label.right {
	color:#999;
}*/
.checklist ul {
  list-style: none;
}

.menulist label, .checklist label {
  font-weight: normal;
}

.menulist input[type=text] {
  display: inline-block;
  width: 300px;
}

.customer_status, .customer_trend {
  font-size: 12px;
  vertical-align: middle;
}

.customer_status.status0 {
  color: rgb(232, 82, 69);
}

.customer_status.status1 {
  color: #284053;
}

.customer_status.status2 {
  color: rgb(0, 158, 224);
}

.customer_status.status3 {
  color: rgb(240, 191, 89);
}

.customer_status.status4 {
  color: rgb(175, 203, 81);
}

.customer_trend.trend0 {
  visibility: hidden;
}

.customer_trend.trend1 {
  color: rgb(175, 203, 81);
}

.customer_trend.trend-1 {
  color: rgb(232, 82, 69);
}

.customer_trend.trend1 span {
  transform: rotate(45deg);
}

.customer_trend.trend-1 span {
  transform: rotate(-45deg);
}

table th .sortable {
  color: #284053;
  text-decoration: underline;
}

table th .sortable .caret {
  opacity: 0;
}

table th .sortable:hover .caret,
table th .sortable.active .caret {
  opacity: 1;
}

.caret.asc {
  border-top: 0;
  border-bottom: 4px solid;
}

.dishes_list.loading {
  opacity: 0.3;
}
.dishes_list img {
  max-height: 70px;
  max-width: 50px;
}

table.dishes_list th.img,
table.dishes_list td.img {
  width: 90px;
}

#edit_tag .form-group-checkbox,
#edit_tag .form-group-color {
  margin-bottom: 0;
}

#options_list .optorder {
  max-width: 50px;
  display: inline-block;
  padding: 3px;
  text-align: center;
  height: 25px;
}

.menulist li input.sup {
  width: 100px;
  margin-left: 10px;
}

#sframe {
  background-color: #fff;
  margin: 0;
  text-align: center;
  padding: 10px;
  max-width: 600px;
  background-color: #eee;
}

#sframe h2 {
  margin: 0;
  background-color: #fff;
}

.pframe {
  width: 100%;
  border: none;
  height: 400px;
  margin: 10px auto;
}

table.options_list .option_values_list {
  max-height: 44px;
  overflow: hidden;
  font-size: 0.8em;
  margin: 0;
  padding-left: 20px;
}

table.options_list.show_values .option_values_list {
  max-height: fit-content;
}

.current_credits {
  background-color: #284053;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  list-style: none;
  color: #fff;
}

.current_credits li {
  padding: 5px 10px;
  border-bottom: 1px solid #fff;
}

.current_credits strong {
  font-weight: normal;
}

.current_credits span {
  display: inline-block;
  text-align: right;
  float: right;
  margin-left: 5px;
  font-weight: bold;
}

.current_credits .sbmt {
  border-bottom: none;
  padding: 0;
}

.current_credits .sbmt a {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 0;
}

.fp_list ul {
  list-style: none;
  padding: 0;
}

.factinfo {
  text-align: right;
  margin: 0 0 10px;
}

.form-control.zxs {
  max-width: 100px;
}

.form-control.zs {
  max-width: 200px;
}

.form-control.zm {
  max-width: 400px;
}

.drag-ico {
  color: #333;
  cursor: move;
  margin: 0 5px;
}

.moving {
  opacity: 0.5;
  background: #C8EBFB;
}

.fieldset-closable .fieldset-toggle {
  cursor: pointer;
}
.fieldset-closable .fieldset-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.2em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.255em solid;
  border-right: 0.255em solid transparent;
  border-bottom: 0;
  border-left: 0.255em solid transparent;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
}

.fieldset-closable:not(.closed) .fieldset-toggle::after {
  transform: rotate(-90deg);
}

.fieldset-closable.closed .fieldset-content {
  display: none;
}

.ticket-formatbar-container {
  border: 1px solid #ccc;
  border-radius: 3px;
}
.ticket-formatbar-container > input,
.ticket-formatbar-container > input:focus,
.ticket-formatbar-container > textarea,
.ticket-formatbar-container > textarea:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.ticket-formatbar-container .ticket-formatbar {
  background-color: #f7f7f9;
  padding: 0;
  margin: 0;
}
.ticket-formatbar-container .ticket-formatbar > li {
  margin: 0 5px 0 0;
  display: inline;
}
.ticket-formatbar-container .ticket-formatbar > li > label {
  font-weight: normal;
}

.dir-filters {
  list-style: none;
  margin: 8px 0 5px;
  padding: 0;
  font-size: 12px;
  color: #666;
}
.dir-filters li {
  display: inline-block;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.dir-filters li a {
  line-height: 2;
  padding: 0.2em;
  text-decoration: none;
}
.dir-filters li.active a {
  font-weight: 700;
  color: #333;
}

.dropdown-menu {
  z-index: 1031;
}

ul.customer_filter_results {
  position: absolute;
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #ddd;
  border-top: none;
  background-color: #fff;
}
ul.customer_filter_results a {
  padding: 5px 8px;
  color: #333;
  display: block;
  border-bottom: 1px solid #ddd;
}
ul.customer_filter_results a:hover {
  background-color: #009ee0;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.text-compact {
  max-width: 800px;
  margin: auto;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.markdown-content blockquote {
  padding: 8px;
  color: #6b6b6b;
  background-color: #eef1ef;
  border-radius: 3px;
}
.markdown-content blockquote p:last-child {
  margin: 0;
}

.customer-detail {
  max-width: 400px;
  min-width: 300px;
  margin: 5px;
  word-wrap: break-word;
  background-color: #fff;
}
.customer-detail .customer-detail-title {
  text-align: center;
  font-size: 1.2rem;
  background-color: #082C49;
  font-weight: 100;
  padding: 4px;
  color: #fff;
  position: relative;
}
.customer-detail .customer-detail-title > h3 {
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  text-align: center;
}
.customer-detail .customer-detail-title .edit {
  position: absolute;
  right: 3px;
}
.customer-detail .customer-detail-th {
  font-weight: bold;
  text-align: right;
}

.fa-zelty::before {
  content: url(/assets/img/zlogo-62879407.svg);
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  vertical-align: bottom;
}

.z-main {
  box-shadow: 0 0 6px #ccc;
  max-width: 500px;
  margin: auto;
}

.z-header {
  padding: 10px;
  text-align: center;
}

.z-section {
  margin: 10px 0;
}