#hpopin_dshadow {
	background-color:rgba(0,0,0,0.4);
	position:fixed;
	width:100%;
	height:100%;
	overflow:hidden;
	overflow-y:auto;
	display:none;
	z-index:9999;
	top:0;left:0;
	-webkit-overflow-scrolling:touch;
}
#hpopin {
	background-color:#fff;
	padding:10px;
	margin:50px auto;
	position:relative;

	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	border-radius:5px;

	width:900px;
}
#hpopin.md {width:600px}
#hpopin.sm {width:350px}
@media (max-width: 940px){
	#hpopin {
		width:600px;
	}
}
@media (max-width: 640px){
	#hpopin {
		width:auto;
		max-width:90%;
	}
}
#hpopin.loading {
	/*TODO: loading state*/
}

	#hpopin > a {
		position:absolute;
		top:-21px;
		right:-21px;
		background:url(/assets/img/close-6d79997d.png) no-repeat center center;
		height:42px;
		width:42px;
		display:block;
	}

.popin-content {display:none}
#hpopin .popin-content {display:block}

#hpopin_content .popin-txt {
	margin:10px;
	text-align:center;
}
#hpopin_content .popin-txt .btn {
	margin:5px;
}

body.popin {overflow:hidden}

#hpopin .popin-header {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}
#hpopin .popin-title {
	margin-bottom: 0;
    line-height: 1.5;
}

#hpopin .popin-body {
	padding: 1rem;
	font-size: 0.9em;
}

#hpopin .popin-footer {
	text-align:right;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    border-bottom-left-radius: .3rem;
    border-bottom-right-radius: .3rem;
}
