.htooltip {display:inline-block}
.htooltip .htip {
	margin:10px -10px;
	background-color:#fff;
	border:1px solid #ccc;
	padding:0 10px 10px;
	position:absolute;
	z-index:11;
	box-shadow:0 0 5px rgba(0,0,0,0.1);
	width:430px;
	display:none;
}
@media (max-width: 430px){
.htooltip .htip {
	width:100vw;
	left:0;
}
}
.htooltip.open .htip {
	display:block;
}

.htooltip .htip:after,
.htooltip .htip:before {
	content:'';
	display:block;
	position:absolute;
	height:0;
	width:0;
	left:20px;
	top:-10px;
	border-bottom: 10px solid #ccc;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	z-index:13;
}
.htooltip .htip:after {
	top:-9px;
	border-bottom: 10px solid #eee;
	z-index:14;
}
.htooltip .htip .tiptitle {
	background-color:#eee;
	padding:5px 10px;
	margin:0 -10px 5px;
}
